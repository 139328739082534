if (!window.inm)
  window.inm = {}

if (!window.inm.polyfill)
  window.inm.polyfill = {}

//Only attach if the polyfill doesn't exist yet, or if _this_ polyfill is newer
if (!window.inm.polyfill.ie11 || window.inm.polyfill.ie11.version < process.env.PACKAGE_VERSION) {
  window.inm.polyfill.ie11 = {
    version: process.env.PACKAGE_VERSION
  }

  //Individual Feature Polyfills
  require('core-js/fn/string/includes')
  require('core-js/fn/symbol/for')
  require('core-js/fn/symbol/key-for')
  require('core-js/fn/symbol/iterator')
  require('core-js/fn/object/get-own-property-names')
  require('core-js/fn/object/assign')
  require('core-js/fn/object/keys')
  require('core-js/fn/number/is-integer')

  //Entire JS Version polyfills.
  require('core-js/es6/promise')
  require('core-js/es6/array')
  require('core-js/es7/array')
  require('whatwg-fetch')
}
